<template>
  <form class="p-field p-3" @submit.prevent="onSubmit(!v$.$invalid)">
    <div class="flex">
      <div class="p-fluid col-3">
        <label for="uniqueId" v-required>Unique Id</label>
        <InputText
          id="uniqueId"
          v-model="v$.uniqueId.$model"
          @input="trimWhitespace"
          :class="{ 'p-invalid': v$.uniqueId.$invalid && submitted }"
          :placeholder="
            v$.uniqueId.$invalid && submitted
              ? 'Champs requis'
              : 'en minuscules, sans espaces'
          "
        />
      </div>
      <div class="p-fluid col">
        <label for="name" v-required>Nom</label>
        <InputText
          id="name"
          v-model="v$.label.$model"
          :class="{ 'p-invalid': v$.label.$invalid && submitted }"
          :placeholder="v$.label.$invalid && submitted ? 'Champs requis' : ''"
        ></InputText>
      </div>
      <div class="p-fluid col-2">
        <label for="security">Règles de sécurité</label>
        <EditRules v-model="rules" />
      </div>
    </div>
    <div class="flex justify-content-end mt-2">
      <Button
        label="Effacer"
        icon="pi pi-times"
        @click="onClear"
        class="p-button-text p-button-secondary mr-2"
      />
      <Button
        label="Ajouter"
        icon="pi pi-plus"
        type="submit"
        class="p-button-success mr-2"
        :disabled="v$.$invalid"
      />
    </div>
  </form>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { projectDatabase } from "../../../firebase/config";
import { useToast } from "primevue/usetoast";

import EditRules from "./_EditRules";

// import _colors from "../../../assets/_colors.scss";

export default {
  components: { EditRules },
  setup() {
    const toast = useToast();
    const newRole = reactive({
      uniqueId: "",
      label: "",
      rules: {},
      // color: "",
    });

    const submitted = ref(false);
    const noSpaces = (value) => !value.includes(" ");
    const validators = {
      uniqueId: { required, noSpaces },
      label: { required },
    };
    const v$ = useVuelidate(validators, newRole);

    const onClear = () => {
      newRole.uniqueId = "";
      newRole.label = "";
      newRole.rules = {};
      submitted.value = false;
    };

    const trimWhitespace = () => {
      newRole.uniqueId = newRole.uniqueId.replace(/\s/g, "");
    };

    const onSubmit = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) return;
      await projectDatabase.ref(`users/roles/${newRole.uniqueId}`).update({
        label: newRole.label,
        rules: newRole.rules,
        // color: newRole.color,
      });
      onClear();
      toast.add({
        severity: "success",
        detail: "Nouveau rôle ajouté !",
        life: 2500,
      });
    };

    return {
      v$,
      validators,
      submitted,
      onSubmit,
      onClear,
      ...toRefs(newRole),
      trimWhitespace,
    };
  },
};
</script>

<style lang="scss" scoped></style>
