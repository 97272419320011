<template>
  <component
    :is="tag"
    :label="rulesCounter"
    :value="rulesCounter"
    :class="[
      !modelValue ? 'bg-secondary' : '',
      tag !== 'Button' ? 'cursor-pointer' : '',
      disabled ? 'pointer-events-none' : '',
    ]"
    @click="visible = true"
    :disabled="disabled"
  />
  <Dialog
    :draggable="false"
    modal
    v-model:visible="visible"
    style="max-width: 90vw"
    class="custom-dialog"
  >
    <template #header>
      <h1 class="display-3">Règles de sécurité</h1>
    </template>
    <template #footer>
      <div class="flex justify-content-end mt-4">
        <Button
          label="Tout effacer"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="selectedRules = []"
        ></Button>
        <Button
          label="Ok"
          icon="pi pi-check"
          iconPos="right"
          class="p-button-success"
          @click="onClose"
        />
      </div>
    </template>
    <DataTable
      :value="rules"
      stripedRows
      rowHover
      class="p-datatable-sm custom-datatable"
      rowGroupMode="subheader"
      groupRowsBy="group.id"
      sortMode="single"
      sortField="group.id"
      :sortOrder="1"
      v-model:selection="selectedRules"
    >
      <template #empty>Aucune règle de sécurité trouvée</template>
      <template #loading>Chargement des données</template>
      <template #groupheader="slotProps">
        <span>{{ slotProps.data.group.label }}</span>
      </template>
      <Column field="group.id" header="Representative"></Column>
      <Column
        selectionMode="multiple"
        style="text-align: center; width: 48px"
      />
      <Column field="label" class="col">
        <template #header>
          <span class="text-sm font-light">Description</span>
        </template>
        <template #body="{ data }">
          {{ data.label }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { isEmpty } from "lodash";

import rulesJSON from "../../../security/rules.json";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: Object,
    tag: {
      type: String,
      default: "Button",
    },
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const visible = ref(false);

    const rules = rulesJSON;

    const selectedRules = ref([]);

    onMounted(() => {
      if (props.modelValue) {
        Object.keys(props.modelValue).map((rule) => {
          const findRule = rules.find((r) => r.id === rule);
          selectedRules.value.push({
            id: rule,
            group: findRule.group,
            label: findRule.label,
          });
        });
      }
    });

    const rulesCounter = computed(() => {
      if (!props.modelValue || isEmpty(props.modelValue)) {
        return "Aucune";
      } else {
        const n = Object.values(selectedRules.value).length;
        if (n === 1) {
          return n + " règle";
        } else {
          return n + " règles";
        }
      }
    });

    const onClose = () => {
      visible.value = false;
      const result = {};
      selectedRules.value.map((rule) => {
        result[rule.id] = true;
      });
      emit("update:modelValue", result);
    };

    return {
      visible,
      rules,
      selectedRules,
      rulesCounter,
      onClose,
    };
  },
};
</script>

<style lang="scss" scoped></style>
