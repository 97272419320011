<template>
  <Accordion :activeIndex="[1]" multiple>
    <AccordionTab header="Ajouter un rôle">
      <Create></Create>
    </AccordionTab>
    <AccordionTab header="Liste des rôles">
      <DataTable
        :value="docs"
        v-model:filters="filters"
        v-model:selection="selectedDocs"
        :loading="pending"
        :paginator="true"
        :rows="rows"
        dataKey="id"
        filterDisplay="row"
        stripedRows
        rowHover
        class="p-datatable-sm"
      >
        <!-- Templates -->
        <template #empty>Aucun rôle</template>
        <template #loading>Chargement des données</template>
        <template #footer>
          <div class="flex align-items-center justify-content-end">
            <div>
              <label class="mr-2">Afficher:</label>
              <Dropdown :options="[10, 25, 50, 100]" v-model="rows" />
            </div>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          style="width: 48px; text-align: center"
        ></Column>
        <!-- Data -->
        <Column
          filterField="id"
          header="UniqueId"
          :showFilterMenu="false"
          class="col-1"
        >
          <template #filter>
            <span class="text-sm font-italic font-light">Unique</span>
          </template>
          <template #body="{ data }">
            <span class="text-sm font-italic">{{ data.id }}</span>
          </template>
        </Column>
        <Column
          header="Libellé"
          filterField="label"
          :showFilterMenu="false"
          class="col"
        >
          <template #filter="{ filterModel, filterCallback }">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback"
                placeholder="Rechercher"
              />
            </span>
          </template>
          <template #body="{ data }">
            <InputText
              v-model="data.label"
              class="p-inputtext-sm inplace"
              :disabled="!data.edit"
            />
          </template>
        </Column>
        <Column
          header="Attribution"
          filterField="attribution"
          :showFilterMenu="false"
          class="col-1"
        >
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="attributions"
              optionValue="value"
              optionLabel="label"
              style="width: 100%"
              placeholder="Tout"
            />
          </template>
          <template #body="{ data }">
            <Badge
              :value="
                attributions.find((a) => a.value === data.attribution).label
              "
              :style="!data.attribution ? 'background-color: #626370' : ''"
              severity="info"
            ></Badge>
            <!--            <Badge v-else value="Aucun" severity="warning"></Badge>-->
          </template>
        </Column>
        <Column>
          <template #header> Règles de sécurité </template>
          <template #body="{ data }">
            <EditRules
              v-model="data.rules"
              tag="Badge"
              :disabled="!data.edit"
            ></EditRules>
          </template>
        </Column>
        <!-- Actions -->
        <Column class="text-right col-1" :showFilterMenu="false">
          <template #filter>
            <Button
              icon="pi pi-trash"
              class="p-button-sm"
              :class="
                !selectedDocs.length
                  ? 'p-button-text p-button-secondary'
                  : 'p-button-danger'
              "
              @click="onRemoveSelected($event)"
              :disabled="!selectedDocs.length"
            />
          </template>
          <template #body="{ data }">
            <Button
              :icon="data.edit ? 'pi pi-check' : 'pi pi-pencil'"
              :class="
                data.edit
                  ? 'p-button-success'
                  : 'p-button-text p-button-secondary'
              "
              @click="onEdit(data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-text p-button-danger"
              @click="onRemove($event, data)"
            />
          </template>
        </Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import useDatabase from "../../../composables/useDatabase";
import { FilterMatchMode } from "primevue/api";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import Create from "./_Create";
import EditRules from "./_EditRules";
import { projectDatabase, projectFunctions } from "../../../firebase/config";

export default {
  components: { Create, EditRules },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();

    const { getAll, updateOne, removeOne } = useDatabase("users/roles");
    const { docs, pending, error } = getAll();

    watch([docs, pending], async () => {
      if (!pending.value) {
        if (!docs.value.find((d) => d.id === "admin")) {
          await updateOne("admin", { label: "Administrateur" });
        }
        docs.value.map((doc) => {
          doc.attribution = isAttributed(doc.id);
        });
      }
    });

    const rows = ref(10);

    const filters = ref({
      label: { value: null, matchMode: FilterMatchMode.CONTAINS },
      attribution: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const selectedDocs = ref([]);

    const onEdit = async (data) => {
      if (data.edit) {
        const update = { ...data };
        delete update.id;
        delete update.edit;
        delete update.attribution;
        await updateOne(data.id, update);
        toast.add({
          severity: "success",
          detail: "Modifications enregistrées !",
          life: 1500,
        });
        data.edit = false;
      } else {
        data.edit = true;
      }
    };

    const users = ref([]);
    onMounted(async () => {
      const res = await projectFunctions.httpsCallable("listUsers").call(null);
      users.value = res.data;
    });

    const isAttributed = (id) => {
      return users.value.some((user) => user.customClaims.role === id);
    };

    const attributions = ref([
      { label: "Tout", value: undefined },
      { label: "Attribué", value: true },
      { label: "Aucun", value: false },
    ]);

    const onRemoveSelected = async (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Êtes-vous sûr de vouloir supprimer le rôles ?`,
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        accept: async () => {
          const selectedRoles = [];
          selectedDocs.value.map((u) => {
            selectedRoles.push(u.id);
          });
          const isOnUse = users.value.some((user) =>
            selectedRoles.includes(user.customClaims.role)
          );
          if (selectedDocs.value.length && !isOnUse) {
            const updates = {};
            selectedDocs.value.forEach((doc) => {
              updates[`users/roles/${doc.id}`] = null;
            });
            await projectDatabase.ref().update(updates);
            selectedDocs.value = [];
            toast.add({
              severity: "info",
              detail: "Les rôles ont été supprimé !",
              life: 2500,
            });
          } else {
            toast.add({
              severity: "error",
              detail:
                "Des rôles sont actuellement attribués et ne peuvent être supprimés.",
              life: 2500,
            });
          }
        },
      });
    };

    const onRemove = (event, data) => {
      confirm.require({
        target: event.currentTarget,
        message: `Êtes-vous sûr de vouloir supprimer le rôle ${data.label} ?`,
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        accept: async () => {
          // const users = await projectFunctions
          //   .httpsCallable("listUsers")
          //   .call(null);
          const isOnUse = users.value.some(
            (user) => user.customClaims.role === data.id
          );
          if (!isOnUse) {
            await removeOne(data.id);
            toast.add({
              severity: "info",
              detail: "Le rôle à été supprimé !",
              life: 1500,
            });
          } else {
            toast.add({
              severity: "error",
              detail:
                "Vous ne pouvez pas supprimer un rôle actuellement attribué.",
              life: 2500,
            });
          }
        },
      });
    };

    return {
      docs,
      pending,
      error,
      rows,
      filters,
      selectedDocs,
      onEdit,
      onRemove,
      onRemoveSelected,
      isAttributed,
      attributions,
    };
  },
};
</script>

<style lang="scss" scoped></style>
